import {createRouter, createWebHistory} from 'vue-router'
import {useAppStore} from '@/stores/app'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/HomeView.vue'),
            meta: {
                requiresAuth: false,
                query: true
            }
        },
        /*{
            path: '/:query',
            name: 'home-query',
            component: () => import('@/views/HomeView.vue'),
            meta: {
                requiresAuth: false
            }
        },*/
        {
            path: '/nft-details/:id',
            name: 'nft-details',
            component: () => import('@/views/NFTDetails.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/listed-nfts',
            name: 'listed-nfts',
            component: () => import('@/views/ListedNFTs.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/list-nft/:id',
            name: 'list-nft',
            component: () => import('@/views/ListNFT.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/unlist-nft/:id',
            name: 'unlist-nft',
            component: () => import('@/views/UnlistNFT.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue')
        },
        {
            path: '/not-found',
            name: 'not-found',
            component: () => import('@/views/NotFound.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/not-found'
        }
    ]
})

router.beforeEach((to) => {
    if (to.name !== 'login') {
        const appStore = useAppStore()
        if (!appStore.isLoggedIn() && to.meta.requiresAuth) {
            return {name: 'login'}
        }
    }
})

export default router
