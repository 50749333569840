import { defineStore } from 'pinia'
import {
    type Eip1193Provider,
    type JsonRpcApiProvider,
    type JsonRpcSigner,
    type JsonRpcProvider
} from 'ethers'
import {isAuthenticated} from '@/libs/auth'
import axios from '@/libs/axios'
import { useAppStore } from '@/stores/app'
import { ref, type Ref } from 'vue'
import chainAddresses from '@/libs/addresses'
import type { AxiosResponse } from 'axios'
import EthereumProvider from '@walletconnect/ethereum-provider'
import {
    type IERC20,
    type IMarineMoguls,
    type IMarineMogulsMarket
} from '@/types/typechain-types'
import router from '@/router'
import type {WebConfig} from '@/types/Common'
import {captureException} from '@sentry/vue'
import type {Currency} from '@/types/Currency'
import {toast} from 'vue3-toastify'
import i18n from '@/libs/i18n'

const Provider = import('@walletconnect/ethereum-provider')
const ethers = import('ethers')
const IERC20__factory = import('@/types/typechain-types/factories/IERC20__factory')
const IMarineMoguls__factory  = import('@/types/typechain-types/factories/IMarineMoguls__factory')
const IMarineMogulsMarket__factory = import('@/types/typechain-types/factories/IMarineMogulsMarket__factory')

type ChainData = {
    readProvider: JsonRpcProvider | null
    usedProvider: number
    chainId: Ref<string>
    address: string
    signer: JsonRpcSigner | null
    provider: JsonRpcApiProvider | null
    rawProvider: Eip1193Provider | EthereumProvider | null
    walletUnlockRequested: boolean
    walletConnectProvider: EthereumProvider | null
}


export const useChainStore = defineStore('chain', () => {
    const appStore = useAppStore()

    const chainData: ChainData = {
        readProvider: null,
        usedProvider: 0,
        chainId: ref('0x38'),
        address: '',
        signer: null,
        provider: null,
        rawProvider: null,
        walletUnlockRequested: false,
        walletConnectProvider: null
    }
    const isStoreLoad: Ref<boolean> = ref(false)
    let isChainStoreInitialised = false
    let isChainStoreInitializationInProgress = false
    let isWalletConnectInitialized = false
    const referralLink: Ref<string> = ref('')
    const ERC20Interfaces: Record<string, IERC20> = {}
    let marineMogulsContract : IMarineMoguls | null = null
    let marineMogulsMarketContract : IMarineMogulsMarket | null = null

    // web config
    let webConfig : WebConfig | undefined = undefined
    let isWebConfigLoading : Boolean = false

    // functions
    const login = async(walletAddress: string): Promise<void> => {
        const currentTimestamp: number = Number((new Date().getTime() / 1000).toFixed(0))
        const addressFrom: string = walletAddress

        try {
            if (chainData.rawProvider) {
                const reconnectSet = localStorage.getItem('last_connection_reconnect_try')
                if (reconnectSet) {
                    localStorage.removeItem('last_connection_reconnect_try')
                    localStorage.removeItem('last_connection')
                    await appStore.disconnect()
                    throw new Error('Reconnect failed')
                }
                localStorage.setItem('last_connection_reconnect_try', 'true')

                const signingMessage = `LoginRequest\nNonce: ${currentTimestamp}`
                const signature = await chainData.rawProvider.request({
                    method: 'personal_sign',
                    params: [signingMessage, addressFrom]
                })

                const payload = {
                    wallet_address: addressFrom,
                    nonce: currentTimestamp,
                    signature
                }

                const response: AxiosResponse<string> = await axios.post('/api/v1/moguls_market/login', payload)
                await appStore.setLogin(response.data)
                localStorage.removeItem('last_connection_reconnect_try')
                if (router.currentRoute.value.name === 'login') await router.replace({name: 'home'})
            }
        } catch (e) {
            localStorage.removeItem('last_connection_reconnect_try')
            localStorage.removeItem('last_connection')
            await appStore.disconnect()
            captureException(e)
            throw e
        }
    }

    const signIn = async(walletAddress: string): Promise<void> => {
        appStore.startLoading()
        try {
            const JWT = localStorage.getItem('JWT')
            if (!JWT) await login(walletAddress)
            else if (!isAuthenticated() || isStoreLoad.value) await login(walletAddress)
            else await appStore.setLogin(JWT)

            isStoreLoad.value = true
        } catch (e) {
            captureException(e)
            await appStore.disconnect()
        } finally {
            appStore.stopLoading()
        }
    }

    const walletAddressChanged = async(newAddress: string): Promise<void> => {
        appStore.startLoading()
        try {
            if (newAddress && chainData.provider) {
                chainData.signer = await chainData.provider.getSigner()
                await signIn((await ethers).getAddress(newAddress))
            }
        } catch (e) {
            captureException(e)
        } finally {
            appStore.stopLoading()
        }
    }

    const handleChainChanged = async(chainId: string): Promise<void> => {
        appStore.startLoading()
        try {
            if (chainData.provider) {
                chainData.chainId.value = chainId
                chainData.signer = await chainData.provider.getSigner()
            }
        } catch (e) {
            captureException(e)
        } finally {
            appStore.stopLoading()
        }
    }

    const connectMetamask = async(): Promise<void> => {
        appStore.startLoading()
        try {
            // eslint-disable-next-line no-use-before-define
            if (!isChainStoreInitialised) await initChainStore()

            // eslint-disable-next-line no-use-before-define
            removeMetamaskAccountsChangedListener()

            chainData.signer = {} as JsonRpcSigner

            if (window.ethereum) {
                chainData.walletUnlockRequested = true

                chainData.rawProvider = window.ethereum
                chainData.provider = new (await ethers).BrowserProvider(window.ethereum)

                const addresses = (await window.ethereum.request({
                    method: 'eth_requestAccounts'
                })) as string[]
                if (addresses.length > 0) {
                    chainData.rawProvider = window.ethereum
                    chainData.provider = new (await ethers).BrowserProvider(window.ethereum)
                    chainData.usedProvider = 1
                    const network = await chainData.provider.getNetwork()
                    await handleChainChanged((await ethers).toQuantity(network.chainId))
                    await walletAddressChanged(addresses[0])
                }

                window.ethereum.on('chainChanged', async(chainId: string | any): Promise<void> => {
                    if (chainData.usedProvider !== 1) return
                    chainData.provider = new (await ethers).BrowserProvider(window.ethereum)
                    if (chainId) await handleChainChanged(chainId)
                })

                window.ethereum.on(
                    'accountsChanged',
                    async(accounts: string[] | any): Promise<void> => {
                        if (chainData.usedProvider !== 1) return
                        if (accounts && accounts.length > 0) {
                            await walletAddressChanged(accounts[0])
                            localStorage.setItem('last_connection', 'window.ethereum')
                        }
                    }
                )

                localStorage.setItem('last_connection', 'window.ethereum')
            }
        } catch (e) {
            captureException(e)
        } finally {
            chainData.walletUnlockRequested = false
            appStore.stopLoading()
        }
    }

    const initWalletConnect = async() => {
        appStore.startLoading()
        try {

            if (isWalletConnectInitialized) return

            // eslint-disable-next-line no-use-before-define
            if (!isChainStoreInitialised) await initChainStore()

            // eslint-disable-next-line no-use-before-define
            const webResponse = await getWebConfig()
            const providerLocal = await Provider

            chainData.walletConnectProvider = await providerLocal.EthereumProvider.init({
                projectId: webResponse.wallet_connect_key,
                chains: [56],
                showQrModal: true,
                methods: [
                    'eth_sendTransaction',
                    'personal_sign',
                    'eth_signTypedData',
                    'eth_signTypedData_v4',
                    'eth_sign'
                ],
                events: ['chainChanged', 'accountsChanged', 'disconnect'],
                rpcMap: {
                    56: webResponse.rpc
                },
                metadata: {
                    name: 'MarineMoguls Market',
                    url: 'https://market.marinemoguls.com/',
                    icons: ['https://market.marinemoguls.com/icon/mstile-150x150.png'],
                    description: 'Embark on a Voyage Through the Depths of Financial Wonderland with Marine Moguls, where every token is a treasure map to untold riches and thrilling market adventures.'
                }
            })
            isWalletConnectInitialized = true
        } catch (e) {
            captureException(e)
        } finally {
            appStore.stopLoading()
        }
    }

    const connectWalletConnect = async(): Promise<void> => {
        appStore.startLoading()
        try {
            if (!chainData.walletConnectProvider) {
                await initWalletConnect()
            }

            await chainData.walletConnectProvider!.enable()

            const addresses = (await chainData.walletConnectProvider!).accounts as string[]

            chainData.rawProvider = chainData.walletConnectProvider!
            chainData.provider = new (await ethers).BrowserProvider(await chainData.walletConnectProvider!)

            if (addresses.length > 0) {
                const network = await chainData.provider.getNetwork()
                await handleChainChanged((await ethers).toQuantity(network.chainId))
                if (chainData.walletConnectProvider!) await walletAddressChanged(addresses[0])
            }

            await chainData.walletConnectProvider!.on(
                'accountsChanged',
                async(accounts: string[]): Promise<void> => {
                    if (chainData.usedProvider !== 2) return
                    if (accounts.length > 0) await walletAddressChanged(accounts[0])
                    localStorage.setItem('last_connection', 'wc')
                }
            )

            await chainData.walletConnectProvider!.on(
                'chainChanged',
                async(chainId: string): Promise<void> => {
                    if (chainData.usedProvider !== 2) return
                    chainData.provider = new (await ethers).BrowserProvider(
                        chainData.walletConnectProvider as EthereumProvider
                    )
                    await handleChainChanged((await ethers).zeroPadValue(chainId, 1))
                }
            )

            await chainData.walletConnectProvider!.on('disconnect', () => {
                localStorage.removeItem('last_connection')
                window.location.reload()
            })

            chainData.usedProvider = 2
            localStorage.setItem('last_connection', 'wc')
        } catch (e) {
            captureException(e)
        } finally {
            appStore.stopLoading()
        }
    }

    const disconnectWalletConnectIfConnected = async(): Promise<void> => {
        if (chainData.usedProvider === 2) {
            Object.keys(localStorage)
                .filter((x) => x.startsWith('wc@2'))
                .forEach((x) => localStorage.removeItem(x))
            if (chainData.walletConnectProvider) {
                await (await chainData.walletConnectProvider).disconnect()
            }
        }
    }

    const handleAccountsChangedEvent = async(accounts : Array<string>) => {
        // if user is not logged in or there are no accounts, logout
        if (accounts.length === 0 || !appStore.getLoggedInUser()) {
            await appStore.disconnect()
            await router.replace({name: 'login'})
        }
        // array of accounts is sorted from most used to least used
        // user comes to website, JWT is still active and MetaMask wasn't unlocked yet. User might then perform an action which required
        // unlocked MetaMask. Upon MM Unlock, accountsChanged event is fired.
        // if first address from array matches that from logged in user, we are sure, that user did not switch wallet in MetaMask and
        // can ignore this event
        const idx = accounts.findIndex(a => a.toLowerCase() === appStore.getLoggedInUser().WalletAddress.toLowerCase())
        if (idx === 0) {
            return
        }
        await appStore.disconnect()
        await router.replace({name: 'login'})
    }


    const registerMetaMaskAndWalletConnectAddressListeners = async() => {
        if (chainData && chainData.walletConnectProvider) {
            chainData.walletConnectProvider!.on(
                'accountsChanged',
                async(accounts : Array<string>) => await handleAccountsChangedEvent(accounts)
            )
        }
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', async(accounts : Array<string>) => await handleAccountsChangedEvent(accounts))
        }
    }

    const removeMetamaskAccountsChangedListener = () => {
        if (window.ethereum) window.ethereum.removeListener('accountsChanged', async() => await handleAccountsChangedEvent([]))
        if (chainData && chainData.walletConnectProvider) chainData.walletConnectProvider.removeListener('accountsChanged', async() => await handleAccountsChangedEvent([]))
    }

    const switchChain = async() => {
        if (chainData.usedProvider === 2) return
        appStore.startLoading()

        const bscData = {
            chainId: '0x38',
            chainName: 'Smart Chain',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com']
        }

        try {
            if (chainData.rawProvider) {
                await chainData.rawProvider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: '0x38'}]
                })
            }
        } catch (e) {
            captureException(e)

            if (typeof e === 'object' && e && 'code' in e && 'toString' in e) {
                if (
                    e.code === 4902 ||
                    (e.toString() && e.toString().includes('wallet_addEthereumChain'))
                ) {
                    try {
                        if (chainData.rawProvider) {
                            await chainData.rawProvider.request({
                                method: 'wallet_addEthereumChain',
                                params: [bscData]
                            })
                        }
                    } catch (e) {
                        captureException(e)
                    }
                } else {
                    captureException(e)
                }
            } else {
                captureException(e)
            }
        } finally {
            appStore.stopLoading()
        }
    }

    const reconnectWallet = async() => {
        const lastConnection = localStorage.getItem('last_connection')
        if (!lastConnection) return

        if (lastConnection === 'window.ethereum') {
            if (window.ethereum) {
                try {
                    const accounts = (await window.ethereum.request({
                        method: 'eth_accounts'
                    })) as string[]
                    if (accounts.length > 0) await connectMetamask()
                } catch (e) {
                    captureException(e)
                }
            }
        } else if (lastConnection === 'wc') {
            if (chainData.walletConnectProvider && !chainData.walletConnectProvider.session) return
            await connectWalletConnect()
        }
    }

    const loadWebConfig = async(): Promise<void> => {
        if (isWebConfigLoading) return
        try {
            isWebConfigLoading = true
            const res : AxiosResponse<WebConfig> = await axios.get('/api/v1/moguls_market/web_config')
            webConfig = res.data
        } catch (e) {
            captureException(e)
        } finally {
            isWebConfigLoading = false
        }
    }

    const initChainStore = async() => {
        if (isChainStoreInitialised || isChainStoreInitializationInProgress) return
        try {
            if (!webConfig) await loadWebConfig()
            isChainStoreInitializationInProgress = true
            chainData.readProvider = new (await ethers).JsonRpcProvider(webConfig!.rpc)
            chainData.usedProvider = 0

            referralLink.value = localStorage.getItem('a') ?? ''
            isChainStoreInitialised = true
        } catch (e) {
            captureException(e)
        } finally {
            isChainStoreInitializationInProgress = false
        }

        await reconnectWallet()
    }


    // getters
    const getChainData = () => {
        return chainData
    }

    const getUsdtChain = () => {
        return chainAddresses.usdt
    }

    const getRawProvider = () => {
        return chainData.rawProvider
    }

    const getProvider = async() => {
        if (!chainData.provider) {
            // MetaMask
            const lastConnection = localStorage.getItem('last_connection')
            if (lastConnection === 'window.ethereum') {
                chainData.rawProvider = window.ethereum
                chainData.usedProvider = 1
            } else if (lastConnection === 'wc') {
                // await chainData.walletConnectProvider!.enable()
                await initWalletConnect()
                chainData.rawProvider = chainData.walletConnectProvider
                chainData.usedProvider = 2
            } else {
                toast.error(i18n.global.t('errors.wallet_not_initialized'))
                return
            }

            chainData.provider = new (await ethers).BrowserProvider(chainData.rawProvider!)
            chainData.signer =  await chainData.provider!.getSigner()
        }
        return chainData.provider
    }

    const getSigner = async() => {
        const p = await getProvider()
        if (!p) {
            toast.error(i18n.global.t('errors.wallet_not_initialized'))
            throw new Error('Provider is undefined.')
        }
        return p!.getSigner()
    }


    const getReadProvider = () => {
        return chainData.readProvider
    }

    const getERC20Interface = async(tokenAddress: string): Promise<IERC20> => {
        if (!isChainStoreInitialised) await initChainStore()
        if (!ERC20Interfaces[tokenAddress]) {
            ERC20Interfaces[tokenAddress] = (await IERC20__factory).IERC20__factory.connect(
                tokenAddress,
                chainData.readProvider
            )
        }
        return ERC20Interfaces[tokenAddress]
    }

    const getMarineMogulsInterface = async(): Promise<IMarineMoguls> => {
        if (!isChainStoreInitialised) await initChainStore()
        if (!marineMogulsContract) marineMogulsContract = (await IMarineMoguls__factory).IMarineMoguls__factory.connect(
            webConfig!.marine_moguls,
            chainData.readProvider
        )
        return marineMogulsContract
    }

    const getMarineMogulsMarketInterface = async(): Promise<IMarineMogulsMarket> => {
        if (!isChainStoreInitialised) await initChainStore()
        if (!marineMogulsMarketContract) marineMogulsMarketContract = (await IMarineMogulsMarket__factory).IMarineMogulsMarket__factory.connect(
            webConfig!.marine_moguls_market,
            chainData.readProvider
        )
        return marineMogulsMarketContract
    }

    const getWebConfig = async(): Promise<WebConfig> => {
        if (!webConfig) await loadWebConfig()
        return webConfig as WebConfig
    }

    const getCurrencies = (): Currency[] => {
        if (webConfig) return webConfig.currencies
        return []
    }

    return {
        connectMetamask,
        connectWalletConnect,
        disconnectWalletConnectIfConnected,
        initChainStore,
        referralLink,
        getChainData,
        getUsdtChain,
        getERC20Interface,
        login,
        switchChain,
        registerMetaMaskAndWalletConnectAddressListeners,
        getRawProvider,
        getProvider,
        getMarineMogulsInterface,
        getSigner,
        getReadProvider,
        getMarineMogulsMarketInterface,
        loadWebConfig,
        getWebConfig,
        getCurrencies
    }
})
