<template>
    <button :class="classes" :disabled="disabled" @click="emit('click')">
        <slot />
    </button>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'default',
            validator(value: string) {
                return ['default', 'small', 'large', 'custom'].includes(value)
            }
        },
        variant: {
            type: String,
            default: 'default',
            validator(value: string) {
                return ['default', 'secondary', 'light-green', 'orange', 'dark-blue', 'light-blue'].includes(value)
            }
        },
        fontSize: {
            type: String,
            default: 'text-xl'
        },
        // padding: {
        //   type: String,
        //   default: ''
        // },
        borderColor: {
            type: String,
            default: '',
            validator(value: string) {
                return ['', 'gray', 'white'].includes(value)
            }
        },
        fontColor: {
            type: String,
            default: 'text-black'
        },
        circle: {
            type: Boolean,
            default: false
        },
        square: {
            type: Boolean,
            default: false
        }
    })

    // emits
    const emit = defineEmits<{(e: 'click'): void }>()

    const classes = computed(() => {
        const classes = []
        classes.push(props.fontSize, props.fontColor)

        if (props.block) classes.push('w-full py-1 px-2')
        else if (props.circle) classes.push('w-circle p-3')
        else if (props.size === 'default') classes.push('w-default px-4 py-2')
        else if (props.size === 'small') classes.push('w-small')
        else if (props.size === 'large') classes.push('w-large !p-6')

        if (props.variant === 'default') classes.push('bg-default')
        else if (props.variant === 'secondary') classes.push('bg-secondary')
        else if (props.variant === 'light-green') classes.push('bg-light-green')
        else if (props.variant === 'orange') classes.push('bg-orange')
        else if (props.variant === 'dark-blue') classes.push('bg-dark-blue')
        else if (props.variant === 'light-blue') classes.push('bg-light-blue')

        if (props.disabled) classes.push('disabled')
        if (props.borderColor === 'gray') classes.push('border-gray')
        else if (props.borderColor === 'white') classes.push('border-white')

        if (props.square) classes.push('square')
        return classes
    })
</script>

<style scoped>
button {
  border-radius: 38px;
  font-style: normal;
  text-align: center;
  transition-duration: 300ms;
  font-size: 16px;
  line-height: 19px;

  /*No text copy*/
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.w-default {
  min-width: 11rem;
  font-weight: 600;
  padding: 1rem;
}
.w-small {
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
}

.bg-default {
  background-color: #29A0B3;
  color: white;
}
.bg-default:hover {
  background-color: #BBDFE3;
  color: black;
}
.bg-default.disabled {
  background-color: #BBDFE3;
  color: black;
}
.disabled {
  cursor: not-allowed;
}

.bg-secondary {
  background: #ffffff;
  border: 1px solid #005c55;
  color: black;
}
.bg-secondary:hover {
  background-color: #98bcba;
  color: #ffffff;
}
.bg-secondary.disabled {
  background: #ccdddc61;
  border: 1px solid #ccdddc61;
  color: #98bcba;
}
.bg-light-green {
  background: #ccdddc;
  color: black;
}
.bg-light-green:hover {
  background-color: #98bcba;
  color: black;
}
.bg-light-green.disabled {
  background: #ccdddc61;
  border: 1px solid #ccdddc61;
  color: #98bcba;
}
.bg-orange {
  background-color: #faa819;
  border: 1px solid #faa819;
  color: black;
}
.bg-orange:hover {
  background-color: #ffffff;
  border: 1px solid #de9e21;
}
.bg-orange.disabled {
  background-color: #ffdda0;
  border: 1px solid #faa819;
  color: black;
}
.bg-dark-blue {
  background-color: #20A2B4;
  color: #262B37;
}
.bg-dark-blue:hover {
  background-color: #BBDFE3;
  color: #262B37;
}
.bg-dark-blue.disabled {
  background-color: #262B37;
  color: #58595B;
}
.bg-light-blue {
  background-color: #BBDFE3;
  color: black;
}
.bg-light-blue:hover {
  background-color: #29A0B3;
  color: black;
}
.bg-light-blue.disabled {
  background-color: #262B37;
  color: #58595B;
}

.w-circle {
  aspect-ratio: 1;
  border-radius: 50%;
}
.border-gray {
  border: 0.5px solid var(--color-gray);
}
.border-white {
  border: 1px solid var(--color-white);
}
.square {
  border-radius: 5px;
}
</style>
