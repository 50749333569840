<template>
    <div v-if="appStore.isLoggedIn()" class="header-menu relative z-[12]">
        <Pill padding="p-2 flex items-center justify-between gap-6 sm:gap-16 lg:gap-24 relative z-[14]" @click="openMenu">
            <div class="flex items-center justify-between gap-3">
                <Jazzicon :diameter="30" :address="appStore.getLoggedInUser().WalletAddress" class="h-[30px] rounded-[999px]"/>
                <span class="text-[15px] leading-5 font-semibold">{{ getShortAddress }}</span>
            </div>
            <div class="w-[28px] h-[28px] rounded-[999px] flex items-center justify-center bg-blue-light">
                <IconChevronDown class="text-black" :size="18"/>
            </div>
        </Pill>
        <transition>
            <div v-if="showMenu" class="border-[1px] border-default bg-green-extra-light rounded-b-[5px] z-[13] absolute -mt-2 w-full pt-3 pb-2 px-2">
                <ul>
                    <li>
                        <a class="flex items-center gap-1 text-black text-lg font-medium" href="https://app.marinemoguls.com/" target="_blank">
                            <IconChevronRight :size="18"/> {{$t('general.my_moguls')}}
                        </a>
                    </li>
                    <li class="mt-1">
                        <router-link class="flex items-center gap-1 text-black text-lg font-medium" :to="{name: 'listed-nfts'}">
                            <IconChevronRight :size="18"/> {{$t('general.list_your_mogul_nft')}}
                        </router-link>
                    </li>
                    <li class="mt-1">
                        <a class="flex items-center gap-1 text-black text-lg font-medium cursor-pointer" @click="logout">
                            <IconChevronRight :size="18"/> {{$t('general.logout')}}
                        </a>
                    </li>
                </ul>
                <SocialsComponent class="mt-4"/>
            </div>
        </transition>
    </div>
    <Button v-else @click="router.push({name:'login'})" variant="light-blue" square>{{$t('general.login')}}</Button>
</template>
<script setup lang="ts">
    import Pill from '@/components/Pill.vue'
    import {useRouter, useRoute} from 'vue-router'
    import {useAppStore} from '@/stores/app'
    import Button from '@/components/Button.vue'
    import {IconChevronDown, IconChevronRight} from '@tabler/icons-vue'
    import {computed, ref, type Ref, watch} from 'vue'
    import Jazzicon from '@/components/Jazzicon.vue'
    import SocialsComponent from '@/components/social/SocialsComponent.vue'

    // data
    const route = useRoute()
    const router = useRouter()
    const appStore = useAppStore()
    const showMenu: Ref<boolean> = ref(false)

    // methods
    const openMenu = async() => {
        showMenu.value = !showMenu.value
    }

    const logout = async() => {
        showMenu.value = false
        await router.push({name:'login'})
        if (appStore.isLoggedIn()) {
            await appStore.disconnect()
        }
    }

    const getShortAddress = computed(() => {
        if (appStore.isLoggedIn()) {
            return `${appStore.loggedInUser.WalletAddress.substring(0, 4)}...${appStore.loggedInUser.WalletAddress.substring(appStore.loggedInUser.WalletAddress.length - 6, appStore.loggedInUser.WalletAddress.length)}`
        } else {
            return ''
        }
    })

    const outsideClickListener = (event: Event) => {
        const sidebarEl = document.querySelector('.header-menu')

        if (sidebarEl)  showMenu.value = sidebarEl.isSameNode(event.target as HTMLElement) ||
            sidebarEl.contains(event.target as HTMLElement)
        else showMenu.value = false
    }

    // watch
    watch(() => route.name, () => {
        showMenu.value = false
    })

    watch(showMenu, (newVal) => {
        if (newVal) {
            document.addEventListener('click', outsideClickListener)
        } else {
            document.removeEventListener('click', outsideClickListener)
        }
    })
</script>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}
</style>