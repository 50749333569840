<template>
    <div class="relative min-h-screen">
        <div class="big-m"></div>
        <Header/>
        <div ref="maincard" :class="{'container px-1 sm:px-4 lg:px-16 xl:px-32 mx-auto': (route.name != 'home' && route.name != 'home-query')}">
            <RouterView/>
        </div>
        <div v-if="appStore.isLoading()" class="loaderStyle flex flex-wrap items-center justify-center">
            <LoaderComponent />
        </div>
    </div>
</template>
<script setup lang="ts">
    import {onMounted, onBeforeMount, onBeforeUnmount, ref} from 'vue'
    import {useAppStore} from '@/stores/app'
    import Header from '@/components/header/Header.vue'
    import LoaderComponent from '@/components/loaders/LoaderComponent.vue'
    import {useChainStore} from '@/stores/chain'
    import {useRoute} from 'vue-router'
    import { captureException } from '@sentry/vue'

    // data
    const appStore = useAppStore()
    const chainStore = useChainStore()
    const route = useRoute()
    const maincard = ref()
    const circleSizes = [20, 40, 60]
    const circleColors = ['#b7dce0A0', '#b7dce0A0', '#ccdddcA0']


    // methods
    const onWindowResized = () => {
        appStore.resizeHandler()
    }

    const getRandomArbitrary = (min: number, max: number) => {
        return (Math.random() * (max - min)) + min
    }

    const drawRandomCircles = () => {
        if (!maincard.value) return

        const bb = maincard.value.getBoundingClientRect()
        const leftPos = bb.left + window.scrollX
        const rightPos = bb.right + window.scrollX
        const isMobile = appStore.isMobile()

        const generateCircles = (topMin: number, topMax: number, isTop: boolean) => {
            const y = getRandomArbitrary(topMin, topMax)
            const x = getRandomArbitrary(leftPos * 0.1, rightPos * 0.9)

            const selectedCircleSize = circleSizes[Math.floor(Math.random() * circleSizes.length)]
            const selectedCircleColor = circleColors[Math.floor(Math.random() * circleColors.length)]

            const circle = document.createElement('div')
            circle.style.position = 'absolute'
            circle.style.left = `${x}px`
            if (isTop)circle.style.top = `${y}px`
            else circle.style.top = `${window.screen.height - y}px`
            circle.style.height = `${selectedCircleSize}px`
            circle.style.width = `${selectedCircleSize}px`
            circle.style.background = selectedCircleColor
            circle.style.zIndex = '-1'
            circle.style.borderRadius = '50%'

            maincard.value.appendChild(circle)
        }

        for (let i = 0; i < 4; i++) {
            if (isMobile) generateCircles(50, 300, true)
            else  generateCircles(190, 300, true)
        }

        for (let i = 0; i < 4; i++) {
            generateCircles(20, 100, false)
        }
    }


    // hooks
    const onBeforeUnload = () => {
        window.removeEventListener('resize', onWindowResized)
        window.removeEventListener('beforeunload', onBeforeUnload)
        appStore.stopCurrentTimestampInterval()
    }

    onBeforeMount(async() => {
        await chainStore.registerMetaMaskAndWalletConnectAddressListeners()
    })


    onBeforeUnmount(() => {
        onBeforeUnload()
    })

    // lifecycle hooks
    onMounted(async() => {
        onWindowResized()
        window.addEventListener('resize', onWindowResized)
        setTimeout(drawRandomCircles, 200)
        appStore.resizeHandler()
        appStore.startCurrentTimestampInterval()
        chainStore.loadWebConfig().then() // load in background
        setTimeout(() => {
            const element = document.getElementById('splash-screen-loader')
            if (element) element.style.display = 'none'
        }, 50)

        // beforeunload gets always called when page is refreshed or closed
        window.addEventListener('beforeunload', onBeforeUnload)

        // MOGUL balance can be loaded after app loads
        await appStore.loadWalletBalances()
    })
</script>

<style scoped>
.loaderStyle {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 89;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
}
.big-m {
  position: absolute;
  height: 100%;
  width: 30%;
  background-image: url("./images/big_mm.svg");
  background-repeat: no-repeat;
  background-size: 100% 90vh;
  background-position: 20px 100px;
  z-index: -2;
}
@media screen and (max-width: 850px) {
  .big-m {
    background-size: 80%;
  }
}
</style>
