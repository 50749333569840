<template>
    <div class="header relative" ref="header">
        <div class="px-4 md:px-8 py-4 flex max-[300px]:flex-wrap justify-between gap-2 w-full">
            <router-link :to="{name: 'home'}">
                <img
                    :src="MMLogoFull"
                    alt="Marine Moguls Logo"
                    class="hidden lg:block min-w-[120px] max-w-[120px] w-full"
                />
                <img :src="MMLogo" alt="Marine Moguls Logo" class="block lg:hidden !min-h-[20px] min-w-[50px] w-full pr-4" />
            </router-link>
            <div class="flex flex-col-reverse h-full sm:flex-row gap-2 md:gap-4 relative items-end sm:items-stretch">
                <WalletPill />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import MMLogoFull from '@/assets/images/MMLogoFull.svg'
    import MMLogo from '@/assets/images/MMLogo.svg'
    import WalletPill from '@/components/header/components/WalletPill.vue'
    import {ref} from 'vue'

    // data
    const header = ref()
</script>
