import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import type {ParsedJWT, User, UserData} from '@/types/Login'
import { useChainStore } from '@/stores/chain'
import {type Router} from 'vue-router'
import type {NFT} from '@/types/Account'
import axios from '@/libs/axios'
import type {AxiosResponse} from 'axios'
import type {Mogul} from '@/types/Mogul'
import {captureException} from '@sentry/vue'
import {getJwt, isJWTExpired, parseJwt} from '@/libs/auth'
import type {CurrencyToken} from '@/types/Currency'

export const useAppStore = defineStore('app', () => {
    const chainStore = useChainStore()
    const windowWidth: Ref<number> = ref(0)
    const loaderActive: Ref<number> = ref(0)
    const userData: Ref<User> = ref({} as User)
    const router: Ref<Router | undefined> = ref(undefined)
    const loggedInUser: Ref<UserData> = ref({
        WalletAddress: '',
        IsLoggedIn: false,
        JWT: ''
    })
    const walletNFTs : Ref<Array<NFT>> = ref([])
    const selectedNFT : Ref<NFT|undefined> = ref(undefined)
    const feeAmount: Ref<number> = ref(0)

    // Mogul NFTs
    const MogulNFTs : Ref<Mogul[]> = ref([])
    const CurrencyTokens : Ref<Array<CurrencyToken>> = ref([])

    // sidebar
    const isSidebarOpen : Ref<boolean> = ref(true)
    const headerHeight : Ref<number> = ref(0)

    // timestamp
    const currentTimestamp : Ref<number> = ref(0)
    let currentTimestampInterval : number = 0

    const startLoading = () => {
        loaderActive.value++
    }
    const stopLoading = () => {
        if (loaderActive.value > 0) loaderActive.value--
    }

    const isMobile = (): boolean => {
        return windowWidth.value < 1024
    }

    const resizeHandler = (): void => {
        windowWidth.value = window.innerWidth
    }

    //setters
    const setLoggedInUser = (data: UserData) => {
        loggedInUser.value = data
    }
    const setUserData = (data: User) => {
        userData.value = data
    }

    const selectNFT = (id : number) => {
        const foundNFT = walletNFTs.value.find(nft => nft.id === id)
        if (!foundNFT) return
        localStorage.setItem('selected_nft_id', foundNFT.id.toString())
        selectedNFT.value = foundNFT
    }

    const setWalletNFTs = (NFTs : NFT[]) => {
        walletNFTs.value = NFTs
        if (walletNFTs.value.length > 0) {
            const selectedNFTId = Number(localStorage.getItem('selected_nft_id'))
            if (!isNaN(selectedNFTId) && selectedNFTId !== 0) {
                const foundNFT = walletNFTs.value.find(nft => nft.id === selectedNFTId)
                if (foundNFT) {
                    selectNFT(foundNFT.id)
                    return
                }
            }
            selectNFT(walletNFTs.value[0].id)
        }
    }
    const getWalletNFTs = () : NFT[] => {
        return walletNFTs.value
    }

    const getSelectedNFT = () : NFT|undefined => {
        return selectedNFT.value
    }

    const disconnect = async() => {
        await chainStore.disconnectWalletConnectIfConnected()

        localStorage.removeItem('last_connection')
        localStorage.removeItem('loggedIn')
        localStorage.removeItem('JWT')
        localStorage.removeItem('userData')
        setLoggedInUser({ WalletAddress: '', IsLoggedIn: false, JWT: '' })
        walletNFTs.value = []
        selectedNFT.value = undefined
        MogulNFTs.value = []
        CurrencyTokens.value = []
    }

    const isOnlyAddressConnected = () => {
        return !!loggedInUser.value.WalletAddress && !loggedInUser.value
    }

    const isAddressConnected = () => {
        return !!loggedInUser.value.WalletAddress
    }
    const isLoggedIn = () => {
        return loggedInUser.value.IsLoggedIn
    }

    const isLoading = () => {
        return loaderActive.value > 0
    }
    const getLoggedInUser = () => {
        return loggedInUser.value
    }

    const getUserData = () => {
        return userData.value
    }

    const openSidebar = (state : boolean) => {
        isSidebarOpen.value = state
    }
    const toggleSidebar = () => {
        isSidebarOpen.value = !isSidebarOpen.value
    }

    const setHeaderHeight = (height : number) => {
        headerHeight.value = height
    }
    const getHeaderHeight = () : number => {
        return headerHeight.value
    }

    const startCurrentTimestampInterval = () => {
        if (currentTimestamp.value) clearInterval(currentTimestampInterval)
        currentTimestampInterval = window.setInterval(() => {
            currentTimestamp.value = Number((new Date().getTime() / 1000).toFixed(0))
        }, 100)
    }
    const stopCurrentTimestampInterval = () => {
        if (currentTimestampInterval) clearInterval(currentTimestampInterval)
    }

    const loadMogulNFTs = async() => {
        if (!isLoggedIn()) return
        try {
            startLoading()
            const mogulNFTsResponse : AxiosResponse<{moguls: Mogul[]}> = await axios.get(`/api/v1/moguls_market/wallet-available-moguls/${loggedInUser.value.WalletAddress}?getAll=true`)
            MogulNFTs.value = mogulNFTsResponse.data.moguls
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }
    const getMarineMoguls = (): Array<Mogul> => {
        return MogulNFTs.value ?? []
    }

    const getMarineMogulsNFT = (id : number): Mogul | null => {
        const foundNFT = MogulNFTs.value.find(x => x.nft_id === id)
        if (foundNFT) return foundNFT
        return null
    }

    const loadWalletNFTs = async() => {
        const responseWalletNFts : AxiosResponse<Array<NFT>> = await axios.get('/api/v1/moguls_market/nfts')
        await setWalletNFTs(responseWalletNFts.data)
    }
    const loadWalletTokenBalances = async() => {
        if (!isLoggedIn()) return
        try {
            startLoading()
            const responseCurrencyTokens : AxiosResponse<Array<CurrencyToken>> = await axios.get('/api/v1/moguls_market/users/tokens')
            CurrencyTokens.value = responseCurrencyTokens.data
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }

    const loadFeeAmount = async() => {
        if (!isLoggedIn()) return
        try {
            startLoading()
            const mogulsMarketContract = await chainStore.getMarineMogulsMarketInterface()
            const response = await mogulsMarketContract.feeAmount()
            feeAmount.value = Number(response)
        } catch (e) {
            captureException(e)
        } finally {
            stopLoading()
        }
    }

    const loadWalletBalances = async() => {
        return Promise.all([loadWalletTokenBalances()])
    }

    const setLogin = async(JWT: string): Promise<void> => {
        try {
            const parsedUserData = parseJwt(JWT) as ParsedJWT

            if (!parsedUserData) return

            const loggedInUser: UserData = {
                WalletAddress: parsedUserData.wallet_address,
                IsLoggedIn: true,
                JWT
            }
            setLoggedInUser(loggedInUser)

            localStorage.setItem('JWT', JWT)
            localStorage.setItem('loggedIn', 'true')
            localStorage.setItem('userData', JSON.stringify(loggedInUser))
            await Promise.all([loadWalletNFTs(), loadMogulNFTs(), loadWalletBalances(), loadFeeAmount()])
        } catch (e) {
            captureException(e)
        }
    }

    const tryLoginWithJWT = async() => {
        const JWT = getJwt()
        if (!JWT) return

        const parsedJWT = parseJwt(JWT)
        if (!parsedJWT) return

        if (isJWTExpired(parsedJWT.exp)) return
        await setLogin(JWT)
    }

    const getCurrencyTokens = (): Array<CurrencyToken> => {
        return CurrencyTokens.value
    }

    return {
        windowWidth,
        isMobile,
        startLoading,
        stopLoading,
        resizeHandler,
        setLoggedInUser,
        setUserData,
        isOnlyAddressConnected,
        isAddressConnected,
        isLoggedIn,
        getLoggedInUser,
        getUserData,
        router,
        loggedInUser,
        feeAmount,
        disconnect,
        isLoading,
        isSidebarOpen,
        openSidebar,
        toggleSidebar,
        setHeaderHeight,
        getHeaderHeight,
        currentTimestamp,
        startCurrentTimestampInterval,
        stopCurrentTimestampInterval,
        setWalletNFTs,
        getWalletNFTs,
        getSelectedNFT,
        selectNFT,
        loadMogulNFTs,
        setLogin,
        tryLoginWithJWT,
        getMarineMoguls,
        getMarineMogulsNFT,
        loadWalletTokenBalances,
        getCurrencyTokens,
        loadWalletBalances,
        loadFeeAmount
    }
})
