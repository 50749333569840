import dayjs from '@/libs/dayjs'
import type { ParsedJWT } from '@/types/Login'

const parseJwt = (token: string): ParsedJWT | null => {
  if (!token) {
    return null
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const isAuthenticated = (): boolean => {
  const jwt = localStorage.getItem('JWT')
  if (!jwt) {
    return false
  }

  const decoded = parseJwt(jwt) as ParsedJWT

  return (
    Boolean(jwt) &&
    dayjs().format() < dayjs.unix(decoded.exp).format() &&
    Boolean(localStorage.getItem('loggedIn')) &&
    localStorage.getItem('loggedIn') === 'true'
  )
}

const getJwt = (): string => {
  const jwt = localStorage.getItem('JWT')
  if (!jwt) return ''

  return jwt
}

const isJWTExpired = (exp : number) : boolean => {
  return dayjs().format() > dayjs.unix(exp).format()
}

export { parseJwt, isAuthenticated, getJwt, isJWTExpired }
