import axios, {
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig
} from 'axios'
import router from '@/router'
import { useAppStore } from '@/stores/app'

const axiosInstance: AxiosInstance = axios.create({})

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
        if (error.response.status === 401) {
            const appStore = useAppStore()
            appStore.disconnect().then()
            if (router.currentRoute.value.name !== 'home') {
                router.push({name: 'home'}).then()
            }
        }
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const jwt = localStorage.getItem('JWT')
    if (jwt && jwt !== '') config.headers.Authorization = `Bearer ${jwt}`
    return config
  },
  (error: any) => Promise.reject(error)
)

export default axiosInstance
