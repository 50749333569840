import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from '@/libs/i18n'
import App from './App.vue'
import router from './router'
import {useAppStore} from '@/stores/app'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { createSentry } from '@/libs/sentry'
import {captureException} from '@sentry/vue'
import VueMatomo from 'vue-matomo'

const initialize = async() => {

    const app = createApp(App)
    app.use(i18n)
    app.use(createPinia())
    app.use(Vue3Toastify, {
        autoClose: 3000,
        theme: 'colored'
    } as ToastContainerOptions)

    app.use(VueMatomo, {
        host: 'https://matomo.metfi.io',
        siteId: 16,
        router
    })

    createSentry(app)

    try {
        const appStore = useAppStore()
        await appStore.tryLoginWithJWT()
    } catch (e) {
        captureException(e)
    }

    app.use(router)
    app.mount('#app')
}

initialize()
